import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: "history",
  routes: [
    {
      path: '/',
      name: 'Index',
      components: {
        default: () => import('@/views/Index'),
        Header: () => import('@/components/Header'),
        Bottom: () => import('@/components/Bottom')
      },
    },
    {
      path: '/about',
      name: 'about',
      components: {
        default: () => import('@/views/about'),
        Header: () => import('@/components/Header'),
        Bottom: () => import('@/components/Bottom')
      },
    },
    {
      path: '/serviceContent',
      name: 'serviceContent',
      components: {
        default: () => import('@/views/serviceContent'),
        Header: () => import('@/components/Header'),
        Bottom: () => import('@/components/Bottom')
      },
    },
    {
      path: '/certificate',
      name: 'certificate',
      components: {
        default: () => import('@/views/certificate'),
        Header: () => import('@/components/Header'),
        Bottom: () => import('@/components/Bottom')
      },
    },
    {
      path: '/case',
      name: 'case',
      components: {
        default: () => import('@/views/case'),
        Header: () => import('@/components/Header'),
        Bottom: () => import('@/components/Bottom')
      },
    }
  ],
})
